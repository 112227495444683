<template>
  <div class="router-wrap">
    <div class="router-content">
      <div class="router-title">
        <div class="title-left" ref="title">
          <p>集团业务</p>
          <img src="@/assets/imgs/business/business-title.png" alt="">
        </div>
        <div class="title-button">
          <router-link class="diy-btn" tag="div" to="/BusinessDetails">
            <span v-if="!isEn">查看更多</span>
            <span v-if="isEn">View More</span>
            <img src="@/assets/imgs/common/back.png" alt="">
          </router-link>
        </div>
      </div>
      <div class="business-content">
        <div class="business-title" v-if="!isEn">单用途预付消费卡智能管理与服务</div>
        <div class="business-title en" v-if="isEn">Single purpose prepaid consumption card intelligent management and service</div>
      </div>
      <ul class="business-list" ref="items">
        <li v-for="(val,ind) in businessList" :key="ind"   @click="aboutClick()">
          <p><img :src="baseURL + val.coverImage" alt=""></p>
          <div class="text-overflow" :title="val.title">{{val.title}}</div>
        </li>
      </ul>
      <div class="router-text">
        <img src="@/assets/imgs/business/business-text.png" alt="">
      </div>
    </div>

  </div>
</template>

<script>
import { selectIndexMaterials } from '@/api/common.js'
import { baseURL } from '@/api/config.js'

export default {
  name: 'Business',
  props:{
    activeIndV:[Number],
  },
  data(){
    return {
      isEn: sessionStorage.getItem("langType") === 'en-us' ? true : false,
      baseURL:baseURL,
      businessList:[
      //     {
      //   img:require("@/assets/imgs/business/business-img01.jpg"),
      //   title:"预付消费卡用户服务系统",
      // },{
      //   img:require("@/assets/imgs/business/business-img02.jpg"),
      //   title:"预付消费卡基础管理系统",
      // },{
      //   img:require("@/assets/imgs/business/business-img03.jpg"),
      //   title:"预付消费卡风控赋能系统",
      // },{
      //   img:require("@/assets/imgs/business/business-img04.jpg"),
      //   title:"预付消费卡市场监管系统",
      // },{
      //   img:require("@/assets/imgs/business/business-img05.jpg"),
      //   title:"预付卡",
      // }
      ]
    }
  },
  watch: {
    activeIndV:function(val){
      //console.log(this.activeIndV)
      if(val== 2){
        this.$refs.title.className = "title-left translate"
        this.$refs.items.className = "business-list translate"
      }else{
        this.$refs.title.className = "title-left"
        this.$refs.items.className = "business-list"
      }
    },
  },
  components: {
  },
  created() {
    this.getImags()
  },
  methods:{
    getImags(){
      selectIndexMaterials({
        lang: sessionStorage.getItem("langType"),
        indexType: "business",
      }).then((res)=>{
        if(res.rows.length){
          this.businessList = res.rows;
        }
        console.log(res)
      })
    },
    aboutClick(){
      this.$router.push('/BusinessDetails');
      sessionStorage.setItem('businessInd',1)
    }
  }
}
</script>
<style scoped lang="scss">
.router-wrap{
  height: 100%;
  background: url(../assets/imgs/common/index-bg.jpg) center center;
  overflow: hidden;
  .router-content{
    width: 1200px;
    margin: 0 auto;
    padding-top: 180px;
    color: #fff;
    .router-title{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title-left{
        transform: translateX(-500px);
        &.translate{
          transition: all 0.3s ease-in-out 0.3s;
          transform: translateX(0px);
        }
        &>p{
          line-height: 1;
          font-size: 40px;
          margin-bottom: 26px;
        }
      }
      .title-button{
        display: flex;
        .diy-btn{
          width: 132px;
          height: 44px;
          border: 1px solid #fff;
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          margin-left: 36px;
          &>img{
            margin-left: 14px;
          }
        }
      }
    }
    .business-content{
      margin-top: 50px;
      text-align: center;
      .business-title{
        display: inline-block;
        min-width: 550px;
        padding: 0 30px;
        height: 60px;
        margin: 0 auto;
        background: linear-gradient( to right, rgb(0,123,219) 0%, rgb(2,102,197) 26%, rgb(3,80,175) 52%);
        opacity: 0.749;
        font-size: 26px;
        text-align: center;
        line-height: 60px;
        letter-spacing: 3px;
        &.en{
          font-size: 16px;
        }
      }

    }
    .business-list{
      display: flex;
      justify-content: space-between;
      padding-top: 7vh;
      & li{
        transform: scale(0,0);
        cursor: pointer;
        & p{
          overflow: hidden;
          & img{
            transition: transform 0.75s ease;
            &:hover{
              transition: transform 0.75s ease;
              transform: scale(1.2,1.2);
            }
          }
        }

      }
      &.translate li{
        transform: scale(1,1);
        &:nth-child(1){
          transition: all 0.3s ease-in-out 0.3s;
        }
        &:nth-child(2){
          transition: all 0.3s ease-in-out 0.34s;
        }
        &:nth-child(3){
          transition: all 0.3s ease-in-out 0.38s;
        }
        &:nth-child(4){
          transition: all 0.3s ease-in-out 0.42s;
        }
        &:nth-child(5){
          transition: all 0.3s ease-in-out 0.46s;
        }
      }
      li{
        width: 226px;
        p{
          height: 156px;
          display: flex;
          img{
            width: 100%;
          }
        }
        div{
          background: rgba(57,140,221,0.65);
          height: 40px;
          font-size: 16px;
          line-height: 40px;
          padding: 0 10px;
          text-align: center;
        }
      }
    }
    .router-text{
      margin-top: 20px;
      text-align: right;
    }
  }
}
</style>
